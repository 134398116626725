import React from 'react';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';

import website from '../images/website.png';
import call_rep from '../images/step1_rep.jpg';
import { camelCase } from 'graphql-compose';

const contentWrapCSS = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;

  padding: 100px;

  h1 {
    margin-top: 60px;
    font-size: 62px;
  }

  h2 {
    margin-bottom: 0;
    font-weight: 500;
    color: #517bfe;
    font-size: 24px;
    padding-bottom: 30px;
  }

  a {
    text-decoration: none;
  }
`;

const imageChartCSS = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 20px;

  @media only screen and (max-width: 480px) {
    display: block;

    .column {
      margin: auto;
      margin-bottom: 30px;
    }

    h4 {
      margin-bottom: 35px;
    }
  }

  .column {
    padding: 20px;
    width: 275px;

    img {
      width: 125px;
      margin-bottom: 15px;
    }

    h4 {
      color: #517bfe;
      margin-bottom: 5px;
    }
  }
`;

const ThankYouPage = () => (
  <Layout showHeaderPhone={false}>
    <SEO title="Thank you" />
    <div css={contentWrapCSS}>
      <h2>Thank you for your submission, but...</h2>
      <p>
        Unfortunately, we can only help with federal student loans. For
        assistance with your private student loans, please call{' '}
        <a href="tel:+18886079717">(888) 607-9717</a>.
      </p>
      {/* <h2>Have questions about federal student loans?</h2>
      <div css={imageChartCSS}>
        <div className="column">
          <a href="https://www.docupop.com">
            <img src={website} />
            <h4>Visit our website</h4>
          </a>
        </div>
        <h4 style={{ color: '#517bfe' }}>OR</h4>
        <div className="column">
          <img src={call_rep} style={{ width: 'calc(125px - 5%)' }} />
          <h4>Give us a call</h4>
          <span style={{ color: '#517bfe', fontWeight: 500, fontSize: 16 }}>
            (855) 910-6986
          </span>
        </div>
      </div> */}
    </div>
  </Layout>
);

export default ThankYouPage;
